import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { MAPBOX_ACCESS_TOKEN } from "./../constants";

export default ({ latitude, longitude }) => ({
  async render() {
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

    this.map = new mapboxgl.Map({
      zoom: 14,
      minZoom: 12,
      maxZoom: 16,
      center: this.lngLat(),
      container: this.$refs.map,
      style: "mapbox://styles/mapbox/streets-v11",
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on("load", () => this.drawCircle());
  },

  lngLat() {
    return [parseFloat(longitude), parseFloat(latitude)];
  },

  drawCircle() {
    this.addSource();

    this.map.addLayer({
      id: "data",
      type: "circle",
      source: "circleData",
      paint: {
        "circle-radius": {
          stops: [
            [0, 0],
            [20, this.metersToPixelsAtMaxZoom(600, latitude)],
          ],
          base: 2,
        },
        "circle-color": "#F2545B",
        "circle-opacity": 0.5,
      },
    });
  },

  addSource() {
    this.map.addSource("circleData", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: this.lngLat(),
            },
          },
        ],
      },
    });
  },

  metersToPixelsAtMaxZoom(meters, latitude) {
    const earthCircumference = 40075017;
    const latitudeRadians = latitude * (Math.PI / 180);
    return (
      (meters / earthCircumference) *
      Math.cos(latitudeRadians) *
      Math.pow(2, 20) *
      256
    );
  },
});
